import React from 'react';

import { Flex } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';

const BackgroundOverlay: React.VFC<BoxProps> = ({ ...rest }) => {
  return (
    <Flex
      position="absolute"
      left="0"
      right="0"
      bottom="0"
      top="0"
      width="100%"
      pointerEvents="none"
      overflow="hidden"
      {...rest}
    />
  );
};

export default BackgroundOverlay;
