import { useEffect, useState } from 'react';
import { onClickAnchorLink } from '../utils/helpers';
import { useRouter } from 'next/router';

type Heading = { id: string; text: string | null };

const useOnThisPage = () => {
  const [headings, setHeadings] = useState<Heading[]>([]);
  const { asPath } = useRouter();
  useEffect(() => {
    getHeadings();
  }, [asPath]);

  const getHeadings = () => {
    const textBlockHeadings = document.querySelectorAll(
      '.textBlock h2, .textBlock h3, .textBlock h4'
    );
    if (textBlockHeadings) {
      const headingsDetails = Array.from(textBlockHeadings).map((heading) => {
        return {
          id: heading.id,
          text: heading.textContent,
        };
      });
      setHeadings(headingsDetails);
    }
  };

  return { headings: headings, onClick: onClickAnchorLink };
};

export { useOnThisPage };
