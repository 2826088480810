import React from 'react';

import { Box, Container, Flex } from '@chakra-ui/react';

import { BackgroundOverlay, Page } from '@/components/layout';
import { SiteSettingsType } from '@/api';
import { SectionsWrapper } from '@/components/section';

import type { SectionsWrapperProps } from '@/components/section';

export type HomePageProps = {
  __typename: 'HomePage';
  title: string;
  shortDescription: string;
  metaTitle?: string;
  metaDescription?: string;
  slug: string;
  blocksCollection: {
    items: {
      __typename: 'ContentBlock';
      contentCollection: {
        __typename: 'ContentBlockContentCollection';
        items: SectionsWrapperProps[];
      };
    }[];
  };
};

export type HomeProps = {
  pageData?: HomePageProps;
  siteSettings?: SiteSettingsType;
};

const Home: React.FC<HomeProps> = ({ pageData, siteSettings = {} }) => {
  if (!pageData) {
    return <>No Page Data</>;
  }
  return (
    <Page siteSettings={siteSettings}>
      {pageData.blocksCollection.items.map(
        ({ __typename, contentCollection }, index) => {
          return (
            <Flex
              key={`${__typename}${index}`}
              as="section"
              direction="column"
              position="relative"
              layerStyle={`home.${index}.outer`}
            >
              <BackgroundOverlay
                layerStyle={`home.${index}.outerImageBackground`}
              />
              <Container maxWidth="container.xl">
                <Flex direction="column" layerStyle={`home.${index}.inner`}>
                  {contentCollection.items.map((content, index) => (
                    <Box
                      key={`${content.__typename}${index}`}
                      position={'fullWidth' in content ? undefined : 'relative'}
                    >
                      <SectionsWrapper {...content} />
                    </Box>
                  ))}
                </Flex>
              </Container>
            </Flex>
          );
        }
      )}
    </Page>
  );
};

export default Home;
