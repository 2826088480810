import React from 'react';

import { Container, Stack, Box, Flex, useTheme } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { SiteSettingsType } from '@/api';
import { BackgroundOverlay, Page } from '@/components/layout';
import { SectionsWrapper } from '@/components/section';

import bannerFullWidthDarkPhoneBackground from '@/images/banner-fullwidth-dark-phone.jpeg';

import {
  Breadcrumbs,
  ButtonBack,
  OnThisPage,
  TextBlock,
} from '@/components/ui';

import { BannerFullWidth } from '@/components/ui';

import type { SectionsWrapperProps } from '@/components/section';
import { Image } from '@/theme/chakra-elements';

export type StandardPageProps = {
  __typename: 'Page';
  template: string;
  introContent: string;
  title: string;
  slug: string;
  shortDescription: string;
  metaTitle?: string;
  metaDescription?: string;
  showOnThisPage: boolean;
  hasBackButton: boolean;
  contentSectionsCollection: {
    __typename: 'PageContentSectionsCollection';
    items: SectionsWrapperProps[];
  };
};

export type StandardProps = {
  pageData?: StandardPageProps;
  siteSettings: SiteSettingsType;
};

export type StandardTemplateProps = {
  hero: React.ReactElement;
  hasHeroContent: boolean;
  banner?: React.ReactElement;
  content?: React.ReactElement;
  theme?: string;
} & StandardProps;

export const StandardTemplate: React.FC<StandardTemplateProps> = ({
  pageData,
  siteSettings = {},
  theme = pageData?.template || 'grey',
  hero,
  banner,
  hasHeroContent,
  content = pageData
    ? pageData.contentSectionsCollection.items.map((cm, i) => (
        <SectionsWrapper key={i} {...cm} />
      ))
    : [],
}) => {
  const siteTheme = useTheme();

  return (
    <Page siteSettings={siteSettings} layerStyle={`standard.${theme}.page`}>
      <Box className="hero" position="relative">
        <BackgroundOverlay
          layerStyle={`standard.${theme}.outerImageBackground`}
        />
        {hero}
        <Container maxWidth="container.xl">
          <Stack
            spacing={24}
            width="100%"
            layerStyle={`standard.${theme}.content`}
            paddingTop={
              hasHeroContent
                ? siteTheme.layerStyles.standard[theme].content.paddingTop
                : 0
            }
          >
            {content}
          </Stack>
        </Container>
      </Box>
      {banner}
    </Page>
  );
};

const Standard: React.FC<StandardProps> = ({ pageData, siteSettings = {} }) => {
  const {
    showOnThisPage = false,
    template: theme = 'grey',
    hasBackButton = false,
    introContent = '',
  } = pageData || {};
  const { mainBottomBanner } = siteSettings;
  const router = useRouter();
  const onGoBack = (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    router.back();
  };

  switch (theme) {
    // Note: TODO refactor this to not be theme but if you have an image option
    case 'dark': {
      return (
        <StandardTemplate
          pageData={pageData}
          siteSettings={siteSettings}
          hasHeroContent={introContent !== null}
          hero={
            <Flex
              width="100%"
              color="white"
              backgroundColor="custom.neutral.800"
            >
              <Container maxWidth="container.xl" width="100%">
                <Flex
                  direction={{ base: 'column', lg: 'row' }}
                  justifyContent="space-between"
                >
                  <Box maxWidth="content.md" alignSelf="flex-start">
                    <Breadcrumbs
                      paddingTop={16}
                      marginBottom={{ base: 10, md: 18, lg: 24 }}
                    />
                    {hasBackButton && (
                      <Box marginBottom={{ base: 10, md: 18, lg: 24 }}>
                        <ButtonBack onClick={onGoBack}>Back</ButtonBack>
                      </Box>
                    )}
                    <TextBlock
                      content={introContent}
                      marginBottom={{ base: 10, md: 18, lg: 24 }}
                    />
                  </Box>

                  <Flex alignSelf={{ base: 'center', lg: 'flex-end' }}>
                    <Image
                      src={bannerFullWidthDarkPhoneBackground}
                      width={470}
                      height={568}
                      alt=""
                      quality={100}
                    />
                  </Flex>
                </Flex>
              </Container>
            </Flex>
          }
          banner={
            mainBottomBanner ? (
              <BannerFullWidth {...mainBottomBanner} />
            ) : undefined
          }
        />
      );
    }

    // TODO: Would love to put the mainbanner logic to the cms. might be a
    // SiteSettings thing
    default: {
      return (
        <StandardTemplate
          pageData={pageData}
          siteSettings={siteSettings}
          hasHeroContent={introContent !== null}
          hero={
            <Flex width="100%">
              <Container maxWidth="container.xl" width="100%">
                <Flex
                  direction={{ base: 'column', lg: 'row' }}
                  justifyContent="space-between"
                >
                  <Box alignSelf="flex-start" maxWidth="content.md">
                    <Breadcrumbs
                      paddingTop={16}
                      marginBottom={{ base: 10, md: 18, lg: 24 }}
                    />
                    {hasBackButton && (
                      <Box marginBottom="50px">
                        <ButtonBack onClick={onGoBack}>Back</ButtonBack>
                      </Box>
                    )}
                    <TextBlock content={introContent} />
                  </Box>
                  {showOnThisPage && (
                    <Box paddingTop={16}>
                      <OnThisPage mt={20} />
                    </Box>
                  )}
                </Flex>
              </Container>
            </Flex>
          }
          banner={
            theme !== 'orange' && mainBottomBanner ? (
              <BannerFullWidth {...mainBottomBanner} />
            ) : undefined
          }
        />
      );
    }
  }
};

export default Standard;
