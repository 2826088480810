import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import { useOnThisPage } from '@/hooks';

type Props = FlexProps;
type Heading = { id: string; text: string | null };
type CallbackFunction = () => void;
type OnThisPage = { headings: Heading[]; onClick: CallbackFunction };

const OnThisPage: React.FC<Props> = () => {
  const { headings, onClick } = useOnThisPage();
  if (!headings.length) return null;

  return (
    <Flex
      flexDirection="column"
      w="300px"
      borderRadius="lg"
      backgroundColor="white"
      p={6}
      data-gtm-id="on-this-page"
    >
      <Text textStyle="label" mb="6">
        On this page
      </Text>
      {headings.length
        ? headings.map((heading, i) => (
            <Text
              as="a"
              textStyle="button 2"
              fontSize="sm"
              mb={4}
              key={i}
              cursor="pointer"
              onClick={() => onClick(heading.id)}
            >
              {heading.text}
            </Text>
          ))
        : null}
    </Flex>
  );
};

export default OnThisPage;
