import React from 'react';
import type { NextPage, GetStaticProps, GetStaticPropsResult } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { Home, Standard } from '@/components/pages';
import type { HomePageProps, StandardPageProps } from '@/components/pages';

import {
  getPageBySlug,
  getHomePage,
  getAllPageSlugs,
  getSiteSettings,
  SiteSettingsType,
} from '@/api';

import AmicaMetaTags from '@/utils/meta';

const Index: NextPage<PageProps> = ({ pageData, siteSettings }) => {
  if (!pageData) {
    return <>...</>;
  }

  switch (pageData.__typename) {
    case 'HomePage': {
      return (
        <>
          <AmicaMetaTags
            title={pageData.metaTitle || pageData.title}
            description={pageData.metaDescription || pageData.shortDescription}
            slug=""
          />
          <Home pageData={pageData} siteSettings={siteSettings} />
        </>
      );
    }
    default: {
      return (
        <>
          <AmicaMetaTags
            title={pageData.metaTitle || pageData.title}
            description={pageData.metaDescription || pageData.shortDescription}
            slug={pageData.slug}
          />
          <Standard pageData={pageData} siteSettings={siteSettings} />
        </>
      );
    }
  }
};

export const getStaticPaths = async () => {
  const allPageSlugs = await getAllPageSlugs();
  return { paths: allPageSlugs.map((s) => `/${s.slug}`), fallback: 'blocking' };
};

interface PageProps {
  slug: string;
  pageData: StandardPageProps | HomePageProps;
  siteSettings: SiteSettingsType;
}

interface PageParams extends ParsedUrlQuery {
  slug: string[];
}

export const getStaticProps: GetStaticProps<PageProps> = async (context) => {
  const { slug } = context.params as PageParams;
  const normalSlug = slug ? slug.join('/') : '/';

  const pageData =
    normalSlug === '/' ? await getHomePage() : await getPageBySlug(normalSlug);
  const siteSettings = await getSiteSettings();

  if (!pageData) return { notFound: true };

  const staticProps: GetStaticPropsResult<PageProps> = {
    props: {
      slug: normalSlug,
      pageData,
      siteSettings,
    },
  };

  if ((process.env.REGENERATION_TIME as string) !== '0') {
    staticProps.revalidate = Number(process.env.REGENERATION_TIME as string);
  }

  return staticProps;
};

export default Index;
